import { Controller } from '@hotwired/stimulus'
import Typed from 'typed.js'

export default class extends Controller {
  connect() {
    new Typed(this.element, options)
  }
}

const options = {
  strings: [
    '1972 Datsun 240Z',
    'Porsche 911 black',
    'T-Bird Cabrio',
    'Mustang 1970-1975',
    'Vette red 1971-1980',
    'Lamborghini',
    'Mercedes W123',
    'Ford in the UK',
    'MGB in the USA',
    'VW Beetle',
  ],
  typeSpeed: 130,
  loop: true,
  attr: 'placeholder',
  bindInputFocusEvents: 'placeholder',
  shuffle: true,
  backDelay: 1500,
}
