import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  connect() {
    this.button = document.getElementById('subscribe-newsletter-button')
    this.button?.addEventListener('click', this.show)

    if (!Cookies.get('shown-account-subscribe-notification')) {
      this.show()
    }
  }

  disconnect() {
    this.button?.removeEventListener('click', this.show)
  }

  hide() {
    this.element.classList.add('d-none')
    this.element.classList.remove('d-block')
  }

  show = () => {
    Cookies.set('shown-account-subscribe-notification', true)

    this.element.classList.remove('d-none')
    this.element.classList.add('d-block')
  }
}
