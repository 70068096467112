import { Controller } from '@hotwired/stimulus'

/**
 * Usage:
 * 1. Add data-controller="read-more" attribute to parent
 * 2. Add children div element with .hide-content & data-read-more-target="content"
 * 3. Add children button element with data-action="read-more#toggle" & data-read-more-target="button" attribute.
 *
 * Example:
 *  div data-controller="read-more"
 *    .hide-content data-read-more-target="content"
 *      = [content]
 *     button data-action="read-more#toggle" data-read-more-target="button"
 */
export default class extends Controller {
  static targets = ['content', 'button']

  showMoreHTML = `
    ${I18n.t('read_more')}
    <i class="fa-solid fa-caret-down ms-2" />
  `

  showLessHTML = `
    ${I18n.t('show_less')}
    <i class="fa-solid fa-caret-up ms-2" />
  `

  connect() {
    this.contentTarget.classList.add('hide-content')
    this.buttonTarget.innerHTML = this.showMoreHTML

    if (this.contentTarget.offsetHeight < 200) {
      this.buttonTarget.classList.add('d-none')
    }
  }

  toggle() {
    if (this.contentTarget.classList.contains('hide-content')) {
      this.contentTarget.classList.remove('hide-content')
      this.contentTarget.classList.add('show-content')
      this.buttonTarget.innerHTML = this.showLessHTML

    } else if (this.contentTarget.classList.contains('show-content')) {
      this.contentTarget.classList.remove('show-content')
      this.contentTarget.classList.add('hide-content')
      this.buttonTarget.innerHTML = this.showMoreHTML
    }
  }
}
