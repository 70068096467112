import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  async connect() {
    const data = await this.fetchData()

    const el = document.getElementById('monthly_payment_text')
    const el2 = document.getElementById('monthly_payment_text_notice')
    const el3 = document.getElementById('monthly_rate_text_notice')

    el.innerHTML = `$${data.monthly_payment} / mo.*`
    el2.innerHTML = `$${data.monthly_payment}`
    el3.innerHTML = `${data.monthly_rate}%`
  }

  async fetchData() {
    const response = await fetch(`/financing_options/${App.State.carId}`)
    return response.json()
  }
}
