import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  connect() {
    this.name = this.element.attributes['data-name'].value

    if (this.testCookie(`shown_${this.name}`) === false) {
      setTimeout(() => {
        this.openModal()
        this.setCookie(`shown_${this.name}`)
        this.fireEvent(`${this.name}_shown`)
      }, 2000)
    }

    this.registerEventListeners()
  }

  openModal = () => {
    this.element.classList.remove('d-none')
  }

  registerEventListeners() {
    this.closeElement = this.element.querySelector('#close-button')
    this.signUpElement = this.element.querySelector('#sign-up-button')
    this.readMoreElement = this.element.querySelector('#read-more-link')

    setTimeout(() => {
      this.element.querySelector('#newsletter-form-submit-button').addEventListener('click', () => {
        setTimeout(this.closeModal, 1000)
      })
    }, 1000)

    this.closeElement?.addEventListener('click', () => {
      this.fireEvent(`${this.name}_click_close`)
      this.closeModal()
    })

    this.signUpElement?.addEventListener('click', () => {
      this.fireEvent(`${this.name}_click_sign_up`)
    })

    this.readMoreElement?.addEventListener('click', () => {
      this.fireEvent(`${this.name}_click_read_more`)
    })
  }

  closeModal = () => this.element.classList.add('d-none')

  fireEvent = (event) => {
    window.dataLayer?.push({ event })
  }

  setCookie(cookieName) {
    Cookies.set(cookieName, true, { expires: 7 })
  }

  testCookie(cookieName) {
    return !!Cookies.get(cookieName)
  }
}
