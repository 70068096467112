import axios from 'axios'
import '../controllers'

App.Request = axios.create({
  headers: {
    'X-CSRF-Token':
      App.State.appEnvironment === 'test' ? '' : App.State.csrfToken,
    'X-Requested-With': 'XMLHttpRequest',
  },
})

App.GAEvent = (eventCategory, eventAction, eventLabel, eventValue) => {
  if (typeof window.ga === 'undefined') return

  window.ga('send', {
    hitType: 'event',
    eventAction,
    eventCategory,
    eventLabel,
    eventValue,
    userId: App.State.userId,
  })
}

function supportsWebp() {
  // eslint-disable-next-line no-restricted-globals
  if (!self.createImageBitmap) return false

  const webpData =
    'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
  const blob = fetch(webpData).then((r) => r.blob())
  return createImageBitmap(blob).then(
    () => true,
    () => false
  )
}

document.addEventListener('DOMContentLoaded', () => {
  if (supportsWebp()) {
    App.State.supportsWebp = true
  } else {
    App.State.supportsWebp = false
    document.body.classList.add('no-webp')
  }
})

window.addEventListener('load', () => {
  const gaElements = document.querySelectorAll('.ga-event')

  if (gaElements.length > 0) {
    gaElements.forEach((gaElement) => {
      gaElement.addEventListener('click', (e) => {
        const gaData = JSON.parse(e.currentTarget.getAttribute('data-ga-event'))

        if (App.State.appEnvironment !== 'production') {
          // eslint-disable-next-line no-console
          console.log(gaData)
        }

        App.GAEvent(gaData.category, gaData.action, gaData.label, gaData.value)
      })
    })
  }
})
