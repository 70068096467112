import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.shareButton = this.element
    // eslint-disable-next-line no-undef
    this.dropdownEl = new bootstrap.Dropdown(this.shareButton)

    this.initButton()
  }

  initButton() {
    const shareData = {
      title: this.shareButton.getAttribute('data-share-title'),
      message: this.shareButton.getAttribute('data-share-message'),
      url: this.shareButton.getAttribute('data-share-url'),
    }

    this.shareButton.addEventListener('click', () => {
      if (navigator.share) {
        this.dropdownEl.hide()
        navigator.share(shareData).catch(() => null)
      }
    })
  }
}
